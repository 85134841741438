<template>
  <v-container>
    <v-autocomplete v-model="searchedKeywords" label="Search for a skill" placeholder="Enter something..."
      :items="autoCompleteValues" @change="animateTimeline" auto-select-first chips deletable-chips multiple
      prepend-icon="mdi-magnify" persistent-placeholder></v-autocomplete>
    <div class="d-flex align-center">
      <v-select v-model="selectedFilters" :items="filters" :label="$t('selectFilter')" @change="animateTimeline" multiple
        chips persistent-hint prepend-icon="mdi-filter" icon item-color="amber" color="teal"></v-select>
      <v-btn v-if="sort === 'asc'" icon @click="() => handleSort('desc')">
        <v-icon>mdi-sort-ascending</v-icon>
      </v-btn>
      <v-btn v-else icon @click="() => handleSort('asc')">
        <v-icon>mdi-sort-descending</v-icon>
      </v-btn>
    </div>

    <div class="ml-4 d-flex align-center overline">
      <strong class="result-number mr-1">{{ resultsNumber }}</strong> Result(s)
    </div>

    <v-timeline align-top :dense="$vuetify.breakpoint.xs">
      <v-timeline-item v-for="item in careerTimelineItems" :key="item.id" :icon="item.icon" color="#2c3b53"
        icon-color="#efa951" ref="timelineItem">
        <!-- <template v-slot:opposite>
          <span>Tus eu perfecto</span>
        </template> -->
        <ProCard v-if="item.type === 'professionalExperience'" :description="item.description" :position="item.position"
          :endDate="item.endDate" :establishment="item.establishment" :startDate="item.startDate" :title="item.title"
          :keywords="item.keywords" />
        <EducationCard v-else :description="item.description" :diplomeTitle="item.diplomeTitle" :endDate="item.endDate"
          :establishment="item.establishment" :score="item.score" :startDate="item.startDate" :title="item.title" />
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
import anime from "animejs";
import moment from "moment";
import EducationCard from "./EducationCard.vue";
import ProCard from "./ProCard.vue";
import data from "../data/careerData";

export default {
  components: {
    EducationCard,
    ProCard,
  },
  mounted() {
    const keywordsArrays = data.map((elt) => elt.keywords);
    let keywords = [];
    keywordsArrays.forEach((keywordArray) => {
      keywords = [...keywords, ...keywordArray];
    });
    this.autoCompleteValues = keywords.sort();
    this.animateTimeline();
  },
  data() {
    return {
      selectedFilters: ["certification", "education", "professionalExperience"],
      sort: "asc",
      timelineAnimation: null,
      autoCompleteValues: [],
      searchedKeywords: [],
    };
  },
  methods: {
    handleSort(sortDirection) {
      this.sort = sortDirection;
      this.animateTimeline();
    },
    animateTimeline() {
      if (this.timelineAnimation) {
        this.timelineAnimation.pause();
      }
      let timelineTargets = this.$refs.timelineItem.map((el) => el.$el);
      if (this.sort === "desc") {
        timelineTargets = timelineTargets.reverse();
      }

      this.timelineAnimation = anime.timeline({
        easing: "easeOutExpo",
        duration: 1000,
      });
      this.timelineAnimation.add({
        targets: timelineTargets,
        translateY: 2000,
      });
      timelineTargets.forEach((target) => {
        this.timelineAnimation.add(
          {
            targets: target,
            translateY: 0,
          },
          "-=600"
        );
      });
    },
  },
  computed: {
    sortedTimelineItems() {
      return data.sort((a, b) => {
        if (this.sort === "asc") {
          return (
            moment(b.startDate, "DD/MM/YYYY") -
            moment(a.startDate, "DD/MM/YYYY")
          );
        } else {
          return (
            moment(a.startDate, "DD/MM/YYYY") -
            moment(b.startDate, "DD/MM/YYYY")
          );
        }
      });
    },
    searchedTimelineItems() {
      if (this.searchedKeywords.length === 0) {
        return this.sortedTimelineItems;
      }
      return this.sortedTimelineItems.filter((item) => {
        let isItemExist = false;
        this.searchedKeywords.forEach((keyword) => {
          if (item.keywords.includes(keyword)) {
            isItemExist = true;
          }
        });
        return isItemExist;
      });
    },
    filteredTimelineItems() {
      return this.searchedTimelineItems.filter((item) =>
        this.selectedFilters.includes(item.type)
      );
    },
    careerTimelineItems() {
      return this.filteredTimelineItems;
    },
    resultsNumber() {
      return this.filteredTimelineItems.length;
    },
    filters() {
      return [
        {
          text: this.$t("certifications"),
          value: "certification",
        },
        {
          text: this.$t("education"),
          value: "education",
        },
        {
          text: this.$t("professionalExperience"),
          value: "professionalExperience",
        },
      ];
    },
  },
};
</script>

<style scoped>
.result-number {
  color: #ffc107;
  font-size: 18px;
}
</style>
