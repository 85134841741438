<template>
  <v-container>
    <v-row>
      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/IkxApHkPtDQ?si=-jiDQYDHCMQRjMWs"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Fi09IZzYkBQ?si=HSzCd8oF4eq_dPjg"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/4L8ArpnphZY" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/gtESZyzR-Xo" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/-tSYmKgMfb4" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/M25O6jsDjP4" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/PcBJYpSZslg" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/1Oubuf3z_TI" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/LwbhIalU0WQ" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/mYUey7IfrX0" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>

      <v-col>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/GLB2BCQ4YrI" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>
