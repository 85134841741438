<template>
  <div :class="this.$vuetify.theme.dark ? 'page' : ''">
    <v-container>
      <v-row dense>
        <v-col md="7">
          <v-row dense justify="center">
            <v-col lg="3" md="4" sx="12">
              <img @scroll="profileImgScrollHandler" data-aos="fade-right" data-aos-offset="200" data-aos-delay="50"
                data-aos-duration="1000" height="350" width="350" src="@/assets/gimp-profile.gif" />
            </v-col>

            <v-col md="9" class="d-flex flex-column align-center justify-center overview">
              <center>
                <div ref="name" class="text-h4">GHEMID Mohamed</div>
              </center>

              <div ref="position" class="text-h5 mb-8 text--secondary">
                Software Developer
              </div>

              <div class="d-flex align-center justify-center mt-2">
                <div ref="craftsman" class="software-title text-h5 mb-8  mr-2">
                  🧪 Craftsman
                </div>
                <div ref="agile" class="software-title text-h5 mb-8  mr-2">
                  ⚗️ Agile
                </div>
                <div ref="fullstack" class="software-title text-h5 mb-8  mr-2">
                  💫 Full Stack
                </div>
                <div ref="technicalAdvisor" class="software-title text-h5 mb-8  mr-2">
                  🧙‍♂️ Technical Advisor
                </div>
                <div ref="leadDeveloper" class="software-title text-h5 mb-8  mr-2">
                  👔 Lead Developer
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <div class="fadeInAnimeRef mt-5" data-aos="zoom-in" data-aos-offset="200" data-aos-delay="50"
            data-aos-duration="2000">
            <p class="title-bio"><v-icon size="40" class="mb-1" color="cyan">mdi-account</v-icon> Biography</p>
            <p class="overline text-justify">
              I consider software development to be an <span class="keyword-color">art</span>, requiring complete
              dedication
              and personal investment. In my
              field, where egocentricity reigns supreme, I have enormous respect for <span
                class="keyword-color">humble</span> people who still consider
              themselves students and are always ready to learn and improve.
              I advocate <span class="keyword-color">software craftsmanship</span> in my work, while respecting all
              other
              approaches. I'm meticulous in my work
              because I do it with the utmost honesty, while looking forward to raising the skills of others in my team.
              I enjoy <span class="keyword-color">sharing</span> and <span class="keyword-color">constructive
                criticism</span>. I like to analyze and
              question
              things in order to constantly
              improve them.
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <br />

    <v-container>
      <p class="title-bio mt-2" style="color:beige;"><v-icon size="40" class="mb-1"
          color="cyan">mdi-account-alert</v-icon>
        What can I bring to your company</p>
      <v-list color="#121212" class="overline" tile>
        <v-list-item-group v-model="selectedItem" color="primary">
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
            <v-list-item color="red">
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="list-item-custom">Skills in <span class="keyword-color">writing clean</span> /
                  understandable / extensible
                  code</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1100">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Understanding of the <span class="keyword-color">business logic</span> behind
                  software</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1200">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title><span class="keyword-color">Challenging software requirements</span> to bring out the
                  best
                  solutions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1300">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>High level of <span class="keyword-color">reviewing code</span> of others to detect
                  inconsistencies and upskilling other
                  in writing clean code</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1400">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Deep understanding of <span class="keyword-color">agility</span> and how to employ it
                  in writing code</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1500">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Finding pleasure to <span class="keyword-color">train juniors</span> and
                  upskilling'em</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1600">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Keeping a team <span class="keyword-color">up to date</span> in terms of new
                  technologies</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1700">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Taking the <span class="keyword-color">lead</span> on a team and motivate them to
                  achieve their
                  goals</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1800">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Setting up <span class="keyword-color">scrum</span> method in a team [sprint planning
                  / sprint review / backlog
                  refinement / retrospective]</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div data-aos="fade-up" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1800">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="cyan">mdi-check-decagram-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title><span class="keyword-color">Brainstorming</span> a team around new subjects [event
                  modeling / 3 amigos]</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-container>

    <v-container v-if="!isMobile">
      <p class="title-bio mt-2" style="color:beige;"><v-icon size="40" class="mb-1" color="cyan">mdi-video</v-icon>
        Skills in popularizing complex concepts</p>
      <v-row>
        <v-col>
          <iframe width="220" height="400" src="https://www.youtube.com/embed/qG6olMUxk3E"
            title="💄Decorator Pattern #programming #coding #react #javascript  #desingpatterns #decoratorpattern"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </v-col>
        <v-col>
          <iframe width="220" height="400" src="https://www.youtube.com/embed/lrpiTbDUivE"
            title="👥 Proxy Pattern #programming #coding #education #desingpatterns  #shorts  #code" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </v-col>
        <v-col>
          <iframe width="220" height="400" src="https://www.youtube.com/embed/3D8RtbHP1h4"
            title="🌉 Bridge pattern #programming #coding #education #code #desingpatterns #dotnet #shorts"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </v-col>
        <v-col>
          <iframe width="220" height="400" src="https://www.youtube.com/embed/LIFAv-YM_Tw"
            title="🔌 Adapter pattern #programming #coding #desingpatterns #adapterpattern #code #shorts"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="isMobile">
      <p class="title-bio mt-2" style="color:beige;"><v-icon size="40" class="mb-1" color="cyan">mdi-video</v-icon>
        Skills in popularizing complex concepts</p>
      <v-carousel height="400" hide-delimiters>
        <v-carousel-item>
          <v-row justify="center">
            <iframe width="220" height="400" src="https://www.youtube.com/embed/qG6olMUxk3E"
              title="💄Decorator Pattern #programming #coding #react #javascript  #desingpatterns #decoratorpattern"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </v-row>
        </v-carousel-item>
        <v-carousel-item>
          <v-row justify="center">
            <iframe width="220" height="400" src="https://www.youtube.com/embed/lrpiTbDUivE"
              title="👥 Proxy Pattern #programming #coding #education #desingpatterns  #shorts  #code" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </v-row>
        </v-carousel-item>
        <v-carousel-item>
          <v-row justify="center">
            <iframe width="220" height="400" src="https://www.youtube.com/embed/3D8RtbHP1h4"
              title="🌉 Bridge pattern #programming #coding #education #code #desingpatterns #dotnet #shorts"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </v-row>
        </v-carousel-item>
        <v-carousel-item>
          <v-row justify="center">
            <iframe width="220" height="400" src="https://www.youtube.com/embed/LIFAv-YM_Tw"
              title="🔌 Adapter pattern #programming #coding #desingpatterns #adapterpattern #code #shorts"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-container>

    <v-container>
      <p class="title-bio mt-2"><v-icon size="40" class="mb-1" color="cyan">mdi-head-cog</v-icon>
        Personality</p>
      <v-row justify="center" align="center">
        <v-col data-aos="fade-right" data-aos-offset="200" data-aos-delay="50" data-aos-duration="2000">
          <v-row>
            <v-col>
              <img width="100" src="@/assets/logicitician-pic.png" />
            </v-col>
            <v-col>
              <div class="personality-title"><strong>Logistician</strong></div>
              <div class="personality-title">ISTJ-A / ISTJ-T</div>
            </v-col>
          </v-row>
        </v-col>
        <v-col data-aos="fade-left" data-aos-offset="200" data-aos-delay="50" data-aos-duration="2000">
          <v-row>
            <v-col>
              <img width="100" src="@/assets/advocate-pic.png" />
            </v-col>
            <v-col>
              <div class="personality-title"><strong>Advocate</strong></div>
              <div class="personality-title">INFJ-A / INFJ-T</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <p class="title-bio mt-2" style="color:beige;"><v-icon size="40" class="mb-1"
          color="cyan">mdi-pencil-ruler</v-icon>
        Architectures worked on</p>
    </v-container>

    <div data-aos="fade-right" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
      <v-row style="background-color: #121212ff;">
        <v-container>
          <center>
            <span class="arch-title">MONOLITH</span>
          </center>
        </v-container>
      </v-row>

      <v-row align="center" justify="center" style="background-color: #121212ff;">
        <img class="architectur-img mb-8" src="@/assets/monolith-architecture.gif" />
      </v-row>
    </div>

    <div data-aos="fade-left" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
      <v-row style="background-color: #121212ff;">
        <v-container>
          <center>
            <span class="arch-title">MODULAR MONOLITH</span>
          </center>
        </v-container>
      </v-row>

      <v-row align="center" justify="center" style="background-color: #121212ff;">
        <img class="architectur-img mb-8" src="@/assets/modular-monolith.gif" />
      </v-row>
    </div>

    <div data-aos="fade-right" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
      <v-row style="background-color: #121212ff;">
        <v-container>
          <center>
            <span class="arch-title">MICROSERVICES</span>
          </center>
        </v-container>
      </v-row>

      <v-row align="center" justify="center" style="background-color: #121212ff;">
        <img class="architectur-img mb-8" src="@/assets/microservices.gif" />
      </v-row>
    </div>

    <br />
    <br />
    <br />

    <div data-aos="zoom-in" data-aos-offset="200" data-aos-delay="50" data-aos-duration="1000">
      <v-row align="center" justify="center">
        <v-col md="3" class="d-flex flex-column align-center justify-center hvr-grow">
          <img v-if="this.$vuetify.theme.dark" src="../assets/azure.webp" width="200" />
          <img v-else src="../assets/azure-dark.webp" width="200" />
        </v-col>
        <v-col md="3" class="d-flex flex-column align-center justify-center hvr-grow">
          <img v-if="this.$vuetify.theme.dark" src="../assets/univ-grenoble.webp" width="120" />
          <img v-else src="../assets/univ-grenoble-dark.webp" width="120" />
        </v-col>
        <v-col md="3" class="d-flex flex-column align-center justify-center hvr-grow">
          <img v-if="this.$vuetify.theme.dark" src="../assets/cisco.webp" width="150" />
          <img v-else src="../assets/cisco-dark.webp" width="150" />
        </v-col>
        <v-col md="3" class="d-flex flex-column align-center justify-center hvr-grow">
          <img v-if="this.$vuetify.theme.dark" src="../assets/toeic-logo.webp" width="110" />
          <img v-else src="../assets/toeic-logo-dark.webp" width="110" />
        </v-col>
      </v-row>
    </div>

    <br />
    <br />
    <br />

    <v-container>
      <v-form ref="form" lazy-validation>
        <v-row dense justify="center">
          <v-col cols="8">
            <v-text-field type="number" :rules="nameRules" suffix="€" v-model="tjm" label="TJM ( min 600 )"
              placeholder="Placeholder" outlined></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn :color="tjm && tjm > minTjm ? 'cyan' : 'secondary'" required @click="() => hire()" height="56"
              width="100%" elevation="2">Hire</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-snackbar v-model="snackbar" timeout="50000">
      <v-icon color="green">mdi-gitlab</v-icon> &nbsp; This portfolio is made by VueJS

      <template v-slot:action="{ attrs }">
        <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  mounted() {
    window.addEventListener("scroll", this.profileImgScrollHandler);
    this.animate();
    this.animateSoftwareTitle();
    this.animateName();
  },
  methods: {
    hire() {
      const validationState = this.$refs.form.validate();
      if (validationState === true) {
        window.open('mailto:ghemid_m_dz@yahoo.fr?subject=Mission ( TJM : ' + this.tjm + ' )');
      }
    },
    profileImgScrollHandler() {
      if (
        document.body.scrollTop > 250 ||
        document.documentElement.scrollTop > 250
      ) {
        anime({
          targets: this.$refs.profileImg,
          translateY: -300,
          opacity: [1, 0],
          easing: "spring(1, 80, 10, 0)",
        });
      } else {
        anime({
          targets: this.$refs.profileImg,
          translateY: 0,
          opacity: 1,
          easing: "spring(1, 80, 10, 0)",
        });
      }
    },
    animateName() {
      let timeline = anime.timeline({
        easing: "linear",
        loop: true,
        duration: 3000,
      });

      timeline.add({
        targets: this.$refs.name,
        color: ["rgba(255, 255, 255, 100)", "rgba(0, 188, 212, 100)"],
      });
      timeline.add({
        targets: this.$refs.name,
        color: ["rgba(0, 188, 212, 100)", "rgba(255, 255, 255, 100)"],
      });
    },
    animate() {
      this.timelineAnimation = anime.timeline({
        easing: "easeInOutExpo",
      });
      this.timelineAnimation.add({
        targets: this.$refs.name,
        translateY: [-100, 0],
      });
      this.timelineAnimation.add(
        {
          targets: this.$refs.position,
          translateY: [1000, 0],
        },
        "-=500"
      );
      this.timelineAnimation.add(
        {
          targets: this.$refs.centerImg,
          opacity: [0, 1],
          duration: 2000,
        },
        "-=600"
      );

      this.timelineAnimation.add(
        {
          targets: this.$refs.fadeInAnimeRef,
          opacity: [0, 1],
          translateY: [1000, 0],
          duration: 2000,
        },
        "-=600"
      );
    },
    animateSoftwareTitle() {
      let timeline = anime.timeline({
        easing: "easeOutExpo",
        loop: true,
      });

      timeline.add({
        targets: this.$refs.craftsman,
        translateY: [-20, 0],
        opacity: [0, 1],
      });
      timeline.add({
        targets: this.$refs.craftsman,
        translateY: [0, 20],
        opacity: [1, 0],
      });

      timeline.add({
        targets: this.$refs.agile,
        translateY: [-20, 0],
        opacity: [0, 1],
      });
      timeline.add({
        targets: this.$refs.agile,
        translateY: [0, 20],
        opacity: [1, 0],
      });

      timeline.add({
        targets: this.$refs.fullstack,
        translateY: [-20, 0],
        opacity: [0, 1],
      });
      timeline.add({
        targets: this.$refs.fullstack,
        translateY: [0, 20],
        opacity: [1, 0],
      });

      timeline.add({
        targets: this.$refs.technicalAdvisor,
        translateY: [-20, 0],
        opacity: [0, 1],
      });
      timeline.add({
        targets: this.$refs.technicalAdvisor,
        translateY: [0, 20],
        opacity: [1, 0],
      });

      timeline.add({
        targets: this.$refs.leadDeveloper,
        translateY: [-20, 0],
        opacity: [0, 1],
      });
      timeline.add({
        targets: this.$refs.leadDeveloper,
        translateY: [0, 20],
        opacity: [1, 0],
      });
    },
  },
  data() {
    return {
      timelineAnimation: null,
      snackbar: true,
      tjm: null,
      minTjm: 599,
      nameRules: [
        v => (v && v > 599) || 'Minimum TJM is 600€',
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
};
</script>

<style scoped>
.arch-title {
  font-size: 28px;
  color: beige;
}

.personality-title {
  font-size: 24px;
}

.architectur-img {
  padding: 0;
  display: block;
  margin: 0 auto;
  max-height: 90%;
  max-width: 90%;
}

.title-bio {
  font-size: large;
  align-items: baseline;
}

.block {
  pointer-events: none;
  position: relative;
  width: 128px;
  height: 128px;
  margin: 1px;
  background-color: currentColor;
  font-size: 12px;
  color: #2c3e50;
}

.projectCounter,
.diplomaCounter {
  color: #00acc1;
  font-size: 30px;
  text-align: center;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.software-title {
  position: absolute;
  color: #ef6c00;
  /* top: 100; */
}

.keyword-color {
  color: #ef6c00;
}

.animeTextRotateLeft,
.animeTextRotateRight {
  font-size: 80px;
  opacity: 0.1;
  margin: 20px;
}

.fadeInAnimeRef {
  /* overflow: hidden;
  width: 100%;
  animation: type 2s steps(100, end); */
}

@keyframes type {
  from {
    width: 0;
    color: rgb(0, 255, 0);
  }
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}

.v-list-item__title {
  text-wrap: initial;
}
</style>
