<template>
  <v-card elevation="4" @click="dialog = true">
    <v-card-text>
      <h2>
        <span class="position">{{ position }}</span> {{ title }}
      </h2>
      <p class="overline">
        {{ startDate }} -
        {{ endDate }}
      </p>
      <p class="d-flex overline align-start">
        <v-icon color="teal lighten-1" class="mr-1">mdi-domain</v-icon>
        {{ establishment }}
      </p>
      
      <v-chip-group active-class="deep-purple accent-4 white--text" column>
        <v-chip small :key="keyword" v-for="keyword in keywords">{{
          keyword
        }}</v-chip>
      </v-chip-group>

      <v-expansion-panels v-if="description">
        <v-expansion-panel>
          <v-expansion-panel-header>{{
              $t("details")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="description-text">
            {{ description }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <!-- <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark color="teal darken-3">
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
          </v-toolbar>
          <CircularProgress :techValue="90" />
        </v-card>
      </v-dialog>
    </v-row> -->
  </v-card>
</template>

<script>
// import CircularProgress from './CircularProgress.vue'

export default {
  // components: { CircularProgress },
  data() {
    return {
      dialog: false,
    }
  },
  props: [
    "position",
    "title",
    "description",
    "startDate",
    "endDate",
    "establishment",
    "keywords"
  ],
};
</script>

<style scoped>
.position {
  color: #26a69a;
}

.description-text {
  text-align: justify;
}
</style>
