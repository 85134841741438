<template>
  <v-card
    class="mx-auto d-flex align-start flex-column"
    height="500"
    max-width="440"
    elevation="4"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="80" :src="require(`@/assets/${bannerImg}`)"></v-img>

    <v-card-title
      >{{ projectTitle }}
      <v-chip
        small
        label
        class="ml-2"
        :color="projectType === 'Personnel' ? 'deep-purple accent-1' : 'cyan'"
        >{{ projectType }}</v-chip
      ></v-card-title
    >

    <v-card-text>
      <v-row align="center" class="mx-0 mb-4">
        <div class="text-subtitle-1">
          Complexity
        </div>
        <v-progress-linear
          color="teal"
          buffer-value="0"
          :value="complexity"
          stream
        ></v-progress-linear>
      </v-row>

      <div>
        {{ description }}
      </div>

      <v-divider class="mx-4 my-3"></v-divider>
      <h3>Technologies</h3>
      <v-chip-group active-class="deep-purple accent-4 white--text" column>
        <v-chip small :key="keyword" v-for="keyword in keywords">{{
          keyword
        }}</v-chip>
      </v-chip-group>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions class="d-flex justify-center align-self-center">
      <v-btn v-if="link" color="amber" target="_blank" :href="link" text>
        Visit
      </v-btn>
      <v-btn v-else text> </v-btn>
      <v-btn v-if="repoLink" text target="_blank" :href="repoLink"
        ><v-icon class="mr-1">mdi-github</v-icon> Repository</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: [
    "bannerImg",
    "projectTitle",
    "description",
    "complexity",
    "type",
    "link",
    "keywords",
    "repoLink",
  ],
  computed: {
    projectType() {
      return this.type === "personal" ? "Personnel" : "Pro";
    },
  },
};
</script>

<style scoped>
.position {
  color: #26a69a;
}
.description-text {
  text-align: justify;
}
.card-img {
  height: 100;
}
</style>
