<template>
  <v-app>
    <v-app-bar flat color="#121212" dense fixed dark>
      <v-app-bar-nav-icon @click="drawer = !drawer" dark></v-app-bar-nav-icon>

      <!-- <v-app-bar-title dark>
        <h3 class="body-1">GHEMID <span class="firstname">MOHAMED</span></h3>
      </v-app-bar-title> -->

      <v-spacer></v-spacer>

      <!-- <v-menu offset-y class="mr-3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon dark>
            <v-icon>
              mdi-translate
            </v-icon>
          </v-btn>
        </template>

<v-list>
  <v-list-item v-for="(item, index) in langs" :key="index" class="lang-menu-item">
    <v-list-item-title @click="item.method">{{
      item.text
      }}</v-list-item-title>
  </v-list-item>
</v-list>
</v-menu> -->

      <!-- <v-btn dark icon v-if="this.$vuetify.theme.dark" @click="handleThemeChange">
        <v-icon>mdi-brightness-5</v-icon>
      </v-btn>
      <v-btn dark icon v-else @click="handleThemeChange">
        <v-icon>mdi-brightness-4</v-icon>
      </v-btn> -->

      <template v-slot:extension>
        <v-tabs v-model="tab" centered color="white" dark hide-slider>
          <v-tab class="overline" @click="handleTabClick">{{ $t("tabs.overview") }}</v-tab>
          <v-tab class="overline" @click="handleTabClick">{{ $t("tabs.career") }}</v-tab>
          <v-tab class="overline" @click="handleTabClick">Videos</v-tab>
          <v-tab class="overline" @click="handleTabClick">Articles</v-tab>
          <v-tab class="overline" @click="handleTabClick">{{ $t("tabs.projects") }}</v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <v-main>
      <!-- <v-container fluid> -->
      <div class="padded" />
      <v-tabs-items v-model="tab">
        <v-tab-item :class="this.$vuetify.theme.dark && 'tab-background'">
          <OverviewTab />
        </v-tab-item>
        <v-tab-item :class="this.$vuetify.theme.dark && 'tab-background'">
          <TimelineTab />
        </v-tab-item>
        <v-tab-item :class="this.$vuetify.theme.dark && 'tab-background'">
          <VideosTab />
        </v-tab-item>
        <v-tab-item :class="this.$vuetify.theme.dark && 'tab-background'">
          <ArticlesTab />
        </v-tab-item>
        <v-tab-item :class="this.$vuetify.theme.dark && 'tab-background'">
          <ProjectsTab />
        </v-tab-item>
      </v-tabs-items>

      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src="@/assets/avatar.webp"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                Mohamed GHEMID
              </v-list-item-title>
              <v-list-item-subtitle>ghemid.mohamed@hotmail.com</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item-group v-model="group">
            <v-list-item link href="https://gitlab.com/users/HamadaBrest/projects" target="_blank">
              <v-list-item-icon>
                <v-icon>mdi-gitlab</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Gitlab</v-list-item-title>
            </v-list-item>

            <v-list-item link href="https://github.com/GHEMID-Mohamed" target="_blank">
              <v-list-item-icon>
                <v-icon>mdi-github</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Github</v-list-item-title>
            </v-list-item>

            <v-list-item link href="https://www.linkedin.com/in/ghemid-mohamed" target="_blank">
              <v-list-item-icon>
                <v-icon>mdi-linkedin</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Linkedin</v-list-item-title>
            </v-list-item>

            <v-list-item link href="https://www.youtube.com/@codingtips3774" target="_blank">
              <v-list-item-icon>
                <v-icon>mdi-youtube</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Youtube</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <!-- </v-container> -->
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";
import TimelineTab from "./components/TimelineTab.vue";
import ProjectsTab from "./components/ProjectsTab.vue";
import OverviewTab from "./components/OverviewTab.vue";
import VideosTab from "./components/VideosTab.vue";
import ArticlesTab from "./components/ArticlesTab.vue";

export default {
  name: "App",

  data() {
    return {
      tab: "",
      drawer: false,
    };
  },

  components: {
    Footer,
    ProjectsTab,
    OverviewTab,
    TimelineTab,
    VideosTab,
    ArticlesTab
  },

  methods: {
    changeLanguage(lang) {
      this.$root.$i18n.locale = lang;
    },
    handleThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkTheme", this.$vuetify.theme.dark);
    },
    handleTabClick() {
      window.scrollTo(0, 0);
    }
  },

  computed: {
    langs() {
      return [
        {
          text: "English",
          method: () => this.changeLanguage("en"),
        },
        {
          text: "Français",
          method: () => this.changeLanguage("fr"),
        },
        {
          text: "العربية",
          method: () => this.changeLanguage("ar"),
        },
      ];
    },
  },
};
</script>

<style scoped>
.firstname {
  color: #51d7ef;
}

.lang-menu-item {
  cursor: pointer;
}

.padded {
  margin-top: 100px;
}

.tab-background {
  background-color: #121212;
}
</style>
