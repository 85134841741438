export default [
  // Education :
  {
    id: "27899217-4e3d-4604-89cf-9886c66ed2d2",
    diplomeTitle: "Master 2",
    title: "Génie Informatique",
    score: "Bien",
    description:
      "Le M2GI est une formation de niveau bac+5 formant des ingénieurs logiciel généralistes. Cette formation comporte un tronc commun d’enseignements axés sur le développement d’applications full-stack Web / Mobiles, et sur l’apprentissage de techniques de Génie Logiciel (validation, tests) et plus largement DevOps (intégration continue/GitLab, containers, Kubernetes, ..).",
    establishment: "Université de Grenoble Alpes",
    startDate: "01/09/2017",
    endDate: "31/08/2017",
    icon: "mdi-school",
    type: "education",
    keywords: [
      "Apprentice",
      "Continuos Itegration",
      "DevOps",
      "Full Stack",
      "Gitlab",
      "Grenoble",
      "Mobile",
      "Test",
      "University",
      "Validation",
      "Web",
    ],
  },
  {
    id: "bced63f3-539c-48e3-95b1-83112c939012",
    diplomeTitle: "Master 1",
    title: "Systèmes informatiques complexes et logiciel embarqué",
    description:
      "Le Master 1 filière Scientifique est une formation à spectre large en informatique (matériels, logiciels, réseaux, modèles et outils), avec un accent particulier sur les techniques de conception et de développement du logiciel. Il permet aux étudiants de parfaire leurs connaissances généralistes dans la discipline, et leur apporte un bagage théorique solide - éprouvé lors de mise en pratique- leur permettant de s'adapter rapidement à des environnements fortement évolutifs.",
    establishment: "Université de Bretagne occidentale",
    startDate: "01/09/2016",
    endDate: "31/08/2017",
    icon: "mdi-school",
    type: "education",
    keywords: ["C++", "FPGA", "Network", "Arduino", "Embedded systems"],
  },
  {
    id: "5a6a4f5e-2754-40a6-9323-886ca2cddfb5",
    diplomeTitle: "Master 2",
    title: "Systèmes embarqués et mobilité",
    description:
      "Le parcours Systèmes embarqués est indifférencié, c'est une formation orientée vers l’ingénierie et la recherche dans le domaine des systèmes embarqués. Les systèmes embarqués constituent un domaine en fort développement, que ce soit dans le secteur des produits grand public (smartphones, objets connectés GPS, set top box, consoles de jeux) ou dans le secteur des dispositifs enfouis de contrôle de véhicules (avions, automobiles, drones) et de chaînes de production. L’objectif de ce master est de former les étudiants aux outils et techniques logicielles nécessaires à l’élaboration d’applications embarquées, mais aussi (c’est sa spécificité) de donner aux étudiants la capacité de concevoir des méthodes et outils de développement pour ce domaine dans les secteurs clés : développement logiciel, système d’exploitation, compilation, configuration des ressources matérielles, optimisation, test et vérification...",
    establishment: "Université de Badji Mokhtar",
    startDate: "01/09/2014",
    endDate: "31/08/2016",
    icon: "mdi-school",
    type: "education",
    keywords: [
      "C++",
      "FPGA",
      "Network",
      "Arduino",
      "Embedded systems",
      "Robotic",
    ],
  },
  {
    id: "ded19a30-1f30-4d38-8e91-bab0787c6d9f",
    diplomeTitle: "Licence",
    title: "Systèmes d'informations",
    description:
      "Discipline jeune et souvent considérée comme un simple outil, l’informatique est une science en constante évolution qui modifie profondément notre société. L’objectif des enseignements de la mention Informatique de la licence Sciences, Technologie, Santé est de dégager les concepts fondamentaux mis en jeu en particulier dans le développement de logiciels, permettant ainsi aux étudiant.e.s d’appréhender les principales techniques, d’en comprendre les évolutions et d'y participer.",
    establishment: "Université de Badji Mokhtar",
    startDate: "01/09/2011",
    endDate: "31/08/2014",
    icon: "mdi-school",
    type: "education",
    keywords: ["Computer science", "Algorithms"],
  },
  {
    id: "dc622c0d-19d5-497d-abeb-c50f9f773729",
    diplomeTitle: "Bac",
    title: "Scientifique",
    score: "Bien",
    establishment: "Lycée El Moukaouama",
    startDate: "01/09/2010",
    endDate: "31/08/2011",
    icon: "mdi-school",
    type: "education",
    keywords: [],
  },

  // Certifications :
  {
    id: "dcd22c0d-19d5-497x-abeb-c50f9f773xxx",
    diplomeTitle: "CKAD",
    title: "Kubernetes Certified Application Developer ",
    description:
      "The Certified Kubernetes Application Developer (CKAD) exam certifies that candidates can design, build and deploy cloud-native applications for Kubernetes.",
    establishment: "Udemy",
    startDate: "05/11/2023",
    endDate: "05/01/2024",
    icon: "mdi-certificate",
    type: "certification",
    keywords: ["Kubernetes", "Cloud", "Container as a Service", "k8s"],
  },
  {
    id: "dcd22c0d-19d5-497x-abeb-c50f9f77372e",
    diplomeTitle: "AZ-900",
    title: "Principes de base Microsoft Azure",
    score: "890",
    description:
      "L’examen Azure Fundamentals fournit une opportunité de prouver la connaissance des concepts du cloud, les services de base d’Azure, charges de travail Azure, sécurité et confidentialité dans Azure, ainsi que tarification et support Azure. Les candidats doivent être familiarisé avec les concepts technologiques généraux,, y compris les concepts de mise en réseau, de stockage, de calcul, de support aux applications, et de développement d’applications.",
    establishment: "Microsoft",
    startDate: "04/01/2022",
    endDate: "05/01/2022",
    icon: "mdi-certificate",
    type: "certification",
    keywords: ["Cloud", "Azure"],
  },
  {
    id: "dcd22c0d-19d5-497x-abeb-c50f9f773729",
    diplomeTitle: "TOEIC",
    title: "Listening and Reading",
    score: "875",
    description:
      "Le test TOEIC® Listening and Reading est devenu un critère de référence pour les décideurs du monde entier. Il mesure les compétences de compréhension écrite et orale pour les niveaux débutant à avancé et détermine si une personne peut communiquer en anglais efficacement et avec aisance dans un contexte professionnel avec d’autres personnes, au-delà des frontières et des cultures.",
    establishment: "INSPIRE NATION",
    startDate: "14/07/2021",
    endDate: "14/07/2023",
    icon: "mdi-certificate",
    type: "certification",
    keywords: ["English"],
  },
  {
    id: "dc62oc0d-19d5-497d-abeb-c50f9f773x29",
    diplomeTitle: "CCNA 2",
    title: "Routing and Switching",
    description:
      "Discipline jeune et souvent considérée comme un simple outil, l’informatique est une science en constante évolution qui modifie profondément notre société. L’objectif des enseignements de la mention Informatique de la licence Sciences, Technologie, Santé est de dégager les concepts fondamentaux mis en jeu en particulier dans le développement de logiciels, permettant ainsi aux étudiant.e.s d’appréhender les principales techniques, d’en comprendre les évolutions et d'y participer.",
    establishment: "INSIM",
    startDate: "01/11/2015",
    endDate: "01/08/2016",
    icon: "mdi-certificate",
    type: "certification",
    keywords: ["Routing", "Commutation", "Network"],
  },
  {
    id: "oc622c0d-19d5-f97d-abeb-c50z9f773729",
    diplomeTitle: "DELF",
    title: "Diplôme d'études en langue francaise",
    description:
      "L’utilisateur B2 a acquis un degré d’indépendance qui lui permet d’argumenter pour défendre son opinion, développer son point de vue et négocier. A ce niveau, le candidat fait preuve d’aisance dans le discours social et devient capable de corriger lui-même ses erreurs.",
    score: "B2",
    establishment: "Campus France",
    startDate: "01/10/2015",
    endDate: "31/08/2016",
    icon: "mdi-certificate",
    type: "certification",
    keywords: ["French"],
  },

  // Professional Experience
  {
    id: "dcf22c0d-19d5-497d-abeb-cssf9f7xx729",
    position: "Stagiaire",
    title: "Développeur Full Stack PHP/JS",
    establishment: "Annaba Health Directions",
    startDate: "01/03/2014",
    endDate: "31/06/2014",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: ["PHP", "Javascript", "SQL", "CSS"],
  },
  {
    id: "d00220d-19d5-497d-abeb-cssf9ffxx729",
    position: "Stagiaire",
    title: "Développeur C++",
    establishment: "Fertial .SPA - Société des Fertilisants d’Algérie",
    startDate: "01/03/2016",
    endDate: "31/06/2016",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: ["C++", "Qt", "QML", "CSS"],
  },
  {
    id: "aaa22c0d-19d5-497d-abeb-cssf9f7xx729",
    position: "Stagiaire",
    title: "Développeur JAVA",
    establishment: "Latim",
    startDate: "01/05/2017",
    endDate: "31/06/2017",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: ["Java", "JavaFx", "CSS"],
  },
  {
    id: "dce22c0d-19d5-497d-abeb-cssw9f7xx729",
    position: "Alternant",
    title: "Développeur Full Stack ReactJS/NodeJS",
    establishment: "Vates",
    startDate: "01/09/2017",
    endDate: "31/08/2018",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: ["AngularJS", "ReactJS", "NodeJS", "LevelUp", "noSql", "Git"],
  },
  {
    id: "dcf22c0d-19d5-497d-abeb-css69f7xx729",
    position: "Concepteur/Développeur",
    title: "Full Stack ReactJS/NodeJS",
    establishment: "Vates",
    startDate: "01/09/2018",
    endDate: "10/01/2020",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: ["ReactJS", "NodeJS", "LevelUp", "noSql", "Git"],
  },
  {
    id: "dcfx2c0d-19d5-497d-abeh-cssf9f7xr729",
    position: "Consultant",
    title: "Full Stack ReactJS/NodeJS",
    establishment: "Extia",
    startDate: "13/09/2020",
    endDate: "01/04/2022",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: [
      "Agility",
      "Enzyme",
      "Flow",
      "Git",
      "GraphQL",
      "Jest",
      "KnexJS",
      "LevelUp",
      "NodeJS",
      "PostgreSQL",
      "React-testing-library",
      "ReactJS",
      "ReactNative",
      "Scrum",
      "noSql",
    ],
  },
  {
    id: "dcfx2c0d-19d5-497d-abeh-cssf9f7xr727",
    position: "Consultant",
    title: "Full Stack ReactJS/.NET",
    establishment: "AXA",
    startDate: "12/04/2022",
    endDate: "31/12/2022",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: [
      ".NET Core",
      "C#",
      "Gherkin",
      "SpecFlow",
      "REST",
      "BDD",
      "Kafka",
      "Kibana",
      "ReactJS",
      "XUnit",
      "React-testing-library",
      "Software Craftsmanship",
      "Sonar cube",
    ],
  },
  {
    id: "dcfx2c0d-19d5-497d-abeh-cssf9f7xrasfd",
    position: "Lead Developer",
    title: "Full Stack ReactJS/.NET",
    establishment: "AXA",
    startDate: "01/01/2023",
    endDate: "today",
    icon: "mdi-professional-hexagon",
    type: "professionalExperience",
    keywords: [
      ".NET Core",
      "C#",
      "ReactJS",
      "Leadership",
      "Réferent technique",
      "Vite.js",
    ],
  },
];
