<template>
  <v-card elevation="4">
    <v-card-text>
      <h2>
        <span class="diplome-title">{{ diplomeTitle }}</span> {{ title }}
        <v-chip outlined v-if="score" color="amber"
          ><v-icon class="mr-1">mdi-school-outline</v-icon>{{ score }}</v-chip
        >
      </h2>
      <p class="overline">
        {{ startDate }} -
        {{ endDate }}
      </p>
      <p class="d-flex overline align-start">
        <v-icon color="amber" class="mr-1">mdi-domain</v-icon>
        {{ establishment }}
      </p>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>{{
            $t("details")
          }}</v-expansion-panel-header>
          <v-expansion-panel-content class="description-text">
            {{ description }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: [
    "diplomeTitle",
    "title",
    "description",
    "startDate",
    "endDate",
    "establishment",
    "score",
  ],
};
</script>

<style scoped>
.diplome-title {
  color: #ffc107;
}
.description-text {
  text-align: justify;
}
</style>
