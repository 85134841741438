export default [
  {
    id: "a78992x7-4e3d-4604-x9cf-98f6c66ed2d2",
    projectTitle: "VAMOS!",
    description:
      "A web app and a native mobile app managing the business of the company, talents, employees follow-ups, etc.",
    type: "pro",
    bannerImg: "vamos-banner.webp",
    link: "https://vamos.extia.fr/vamos",
    complexity: "95",
    keywords: [
      "ReactJS",
      "ReactNative",
      "Expo",
      "NextJS",
      "Relay",
      "GraphQL",
      "PostgreSQL",
      "KnexJS",
      "Gitlab CI",
      "Scrum",
      "Backlog Review",
      "Planning Pocker",
      "Git",
    ],
  },
  {
    id: "27899217-4e3d-4604-x9cf-9886c66ed2d2",
    projectTitle: "DZ DEALS",
    description:
      "DZ deals is a progressive web app that publishes the best promotions in Algeria on a daily basis",
    type: "personal",
    bannerImg: "dz-deals-banner.webp",
    link: "https://optimistic-hugle-331928.netlify.app/#/",
    complexity: "55",
    repoLink: "https://gitlab.com/HamadaBrest/l_affaire",
    keywords: [
      "VueJS",
      "Vuex",
      "Vuetify",
      "Heroku",
      "Netlify",
      "Strapi",
      "GraphQL",
      "PostgreSQL",
      "Git",
    ],
  },
  {
    id: "27899217-4e3d-f604-x9cf-9886c66ed2d2",
    projectTitle: "Hakima QCM",
    description:
      "A progressive web app that helps medical students to review and share QCM exams using OCR technologie.",
    type: "personal",
    bannerImg: "hakima-qcm-banner.webp",
    link: "https://hakima-qcm.com/#/",
    complexity: "72",
    repoLink: "https://gitlab.com/HamadaBrest/www-dz-med-qcm",
    keywords: [
      "ReactJS",
      "Freactal",
      "Mongo DBaaS",
      "Reactstrap",
      "TesseractJS",
      "Git",
    ],
  },
  {
    id: "278992d7-4e3d-f60f-x9cf-9886c66ed2d2",
    projectTitle: "Xen Orchestra",
    description:
      "The complete web solution to manage and backup XCP-ng and Citrix Hypervisor. (Turnkey solution for XenServer)",
    type: "openSource",
    bannerImg: "xen-orchestra-banner.webp",
    link: "https://xen-orchestra.com/#!/xo-home",
    complexity: "92",
    repoLink: "https://github.com/vatesfr/xen-orchestra",
    keywords: [
      "NodeJS",
      "ExpressJS",
      "ReactJS",
      "Redux",
      "SQL Lite",
      "React Bootstrap",
      "JSON rpc",
      "Git",
    ],
  },
  {
    id: "278992d7-4e3d-f604-x9cf-9886c66ed2d2",
    projectTitle: "Vates store",
    description:
      "A web app store buying all Vates products, including credit card payment, wire transfer and bank check",
    type: "professional",
    bannerImg: "vates-store-banner.webp",
    link: "https://store.vates.fr/#/login",
    complexity: "78",
    keywords: [
      "Stripe API",
      "NodeJS",
      "ExpressJS",
      "ReactJS",
      "Reactstrap",
      "NoSQL",
      "JSON rpc",
      "Git",
    ],
  },
  {
    id: "27899xd7-4e3d-a604-x9cf-9886c66fd2d2",
    projectTitle: "Vates Support",
    description:
      "A web app connected to Vates website that helps Vates clients to report issues and follow their tickets.",
    type: "professional",
    bannerImg: "vates-support-banner.webp",
    link: "https://support.vates.fr/#/tickets",
    complexity: "65",
    keywords: [
      "NodeJS",
      "ExpressJS",
      "ReactJS",
      "Reactstrap",
      "NoSQL",
      "JSON rpc",
      "Freactal",
      "Git",
    ],
  },
  {
    id: "2789r2d7-4e3d-a604-x9cf-98f6x66ed2d2",
    projectTitle: "Vates admin panel",
    description:
      "An administration web app that manages all Vates clients, including invoices, plan activiations, etc.",
    type: "professional",
    bannerImg: "vates-admin-banner.webp",
    complexity: "70",
    keywords: [
      "NodeJS",
      "ExpressJS",
      "ReactJS",
      "Reactstrap",
      "NoSQL",
      "JSON rpc",
      "Freactal",
      "Git",
    ],
  },
  {
    id: "x789r2d7-4e3d-a604-x9cf-98f6x66ed2d2",
    projectTitle: "Smart Climate",
    description:
      "A Desktop application which processes compares and manipulates weather data, the user can download, import the data even if the application is offline",
    type: "personal",
    bannerImg: "smart-climate-banner.webp",
    complexity: "75",
    link: "https://www.youtube.com/watch?v=gBUDotCsqxo",
    keywords: ["Java", "JavaFx", "CSS", "Javascript"],
  },
  {
    id: "y789r2d7-4e3d-a604-x9cf-98f6x66ed2d2",
    projectTitle: "Newspaper",
    description:
      "A dynamic web application of an electronic newspaper which allows the user to visit illustrated items with photos.",
    type: "personal",
    bannerImg: "newspaper-banner.webp",
    complexity: "60",
    link: "https://www.youtube.com/watch?v=OS5XToPnCho",
    keywords: ["Java", "JEE", "JSTL", "Servlet", "JSP", "Javascript"],
  },
  {
    id: "xa89r2d7-4e3d-a604-x9cf-98f6x66ed2d2",
    projectTitle: "Image editor",
    description: "A desktop application for editing pictures",
    type: "personal",
    bannerImg: "image-editor-banner.webp",
    complexity: "67",
    link: "https://www.youtube.com/watch?v=BTTrsU_n4-I",
    keywords: ["Java", "JavaFx", "CSS"],
  },
  {
    id: "xa89r2d7-4e3d-x604-x9cf-f8f6x66ed2d2",
    projectTitle: "Smart paye",
    description:
      "A desktop application that manages Internet cafe store employees and the money box",
    type: "personal",
    bannerImg: "smart-paye-banner.webp",
    complexity: "77",
    link: "https://www.youtube.com/watch?v=YRDTjcL-isk&feature=youtu.be",
    keywords: ["C++", "Qt", "SQL"],
  },
  {
    id: "xa89f2d7-4e3d-x6x4-x9cf-f8f6x66ed2d2",
    projectTitle: "I2V communication",
    description:
      "Model simulation of a cooperative system (Vehicle infrastructure)",
    type: "personal",
    bannerImg: "i2v-banner.webp",
    complexity: "95",
    link: "https://www.youtube.com/watch?v=8BIw0N8PMHU",
    keywords: [
      "Android",
      "Qt",
      "SQL",
      "Arduino",
      "Ultrasonic sensor",
      "7 segments displayer",
    ],
  },
  {
    id: "xa89a2d7-4e3d-xff4-x9cf-a8f6x66ed2d2",
    projectTitle: "Industrial Inspector",
    description: "SCAAD Method for industrial",
    type: "personal",
    bannerImg: "indus-inspect-banner.webp",
    complexity: "77",
    link: "https://www.youtube.com/watch?v=egFLvClhvds",
    keywords: ["C++", "Qt", "CSS"],
  },
  {
    id: "xa89s2d7-4e3d-xfx4-x9cf-a8f6x66ed2d2",
    projectTitle: "Educational game",
    description: "Algorithmic Binary trees educational game",
    type: "personal",
    bannerImg: "educ-game-banner.webp",
    complexity: "72",
    link: "https://www.youtube.com/watch?v=egFLvClhvds&t=1s",
    keywords: ["C++", "Qt", "CSS"],
  },
  {
    id: "xa89s8d7-4e3d-xax4-x9cf-a8f6x66ed2d2",
    projectTitle: "INESSM QCM",
    description:
      "A React Native App that helps medical students to revise their exams",
    type: "personal",
    bannerImg: "inessm-qcm-banner.webp",
    complexity: "45",
    link:
      "https://drive.google.com/file/d/1HM0JY2cpXC0QMxYUjKNjYGLEA7E2DgzE/view",
    keywords: ["ReactNative", "Javascript", "NativeBase", "Expo"],
    repoLink: "https://gitlab.com/HamadaBrest/medical-DZ-QCM-Exams",
  },
  {
    id: "xa89s8d7-ae3d-xax4-x9cf-a8f6x66vd2d2",
    projectTitle: "Smart Tacos",
    description:
      "An android App that allow users to order a Tacos without touching their phones",
    type: "personal",
    bannerImg: "smart-tacos-banner.webp",
    complexity: "85",
    link: "https://www.youtube.com/watch?v=jxkXAqfsY3Q",
    keywords: ["Android", "Java", "CSS", "XML"],
    repoLink: "https://github.com/HamadaBrest/smartTacosApp",
  },
];
