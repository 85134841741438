<template>
  <v-container>
    <v-autocomplete v-model="searchedKeywords" label="Search for a skill" placeholder="Enter something..."
      :items="autoCompleteValues" auto-select-first chips deletable-chips multiple prepend-icon="mdi-magnify"
      persistent-placeholder @change="animateProjects"></v-autocomplete>

    <div class="ml-4 mb-3 d-flex align-center overline">
      <strong class="result-number mr-1">{{ resultsNumber }}</strong>
      Result(s)
    </div>

    <v-row>
      <v-col sm="6" xs="12" v-for="project in projects" :key="project.id">
        <ProjectCard ref="timelineItem" :bannerImg="project.bannerImg" :complexity="project.complexity"
          :description="project.description" :keywords="project.keywords" :link="project.link"
          :projectTitle="project.projectTitle" :type="project.type" :repoLink="project.repoLink" />
      </v-col>
    </v-row>
    <br />
  </v-container>
</template>

<script>
import anime from "animejs";
import ProjectCard from "./ProjectCard.vue";
import data from "../data/projectsData";

export default {
  components: {
    ProjectCard,
  },
  mounted() {
    const keywordsArrays = data.map((elt) => elt.keywords);
    let keywords = [];
    keywordsArrays.forEach((keywordArray) => {
      keywords = [...keywords, ...keywordArray];
    });
    this.autoCompleteValues = keywords.sort();
    this.animateProjects();
  },
  methods: {
    animateProjects() {
      if (this.timelineAnimation) {
        this.timelineAnimation.pause();
      }

      let projectsTargets = this.$refs.timelineItem.map((el) => el.$el);

      this.timelineAnimation = anime.timeline({
        easing: "easeOutExpo",
        duration: 1000,
      });
      this.timelineAnimation.add({
        targets: projectsTargets,
        translateY: 1000,
      });
      projectsTargets.forEach((target) => {
        this.timelineAnimation.add(
          {
            targets: target,
            translateY: 0,
          },
          "-=900"
        );
      });
    },
  },
  data() {
    return {
      selectedFilters: ["certification", "education", "professionalExperience"],
      sort: "asc",
      timelineAnimation: null,
      autoCompleteValues: [],
      searchedKeywords: [],
    };
  },
  computed: {
    projects() {
      return this.searchedItems;
    },
    searchedItems() {
      if (this.searchedKeywords.length === 0) {
        return data;
      }
      return data.filter((item) => {
        let isItemExist = false;
        this.searchedKeywords.forEach((keyword) => {
          if (item.keywords.includes(keyword)) {
            isItemExist = true;
          }
        });
        return isItemExist;
      });
    },
    resultsNumber() {
      return this.projects.length;
    },
  },
};
</script>

<style scoped>
.result-number {
  color: #ffc107;
  font-size: 18px;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.1);
}
</style>
