<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mx-auto" max-width="550" subtitle="prepend-avatar and append-avatar" title="Avatars">

          <v-img height="100" src="@/assets/image-editor-banner.webp"></v-img>
          <v-card-title> 🔐 Backend For Frontend (BFF)</v-card-title>
          <v-card-text>Toutes les applications web qui utilisent le protocole OAuth 2.0 pour l’authentification sont
            confrontées à de nombreuses failles de sécurité. Parmis les solutions pour faire bouclier à ses attaques,
            c’est le pattern Backend For Frontend (BFF).</v-card-text>
          <v-card-actions>
            <v-btn color="cyan lighten-2" text target="_blank"
              href="https://creativetech-fr.devoteam.com/2024/01/30/comment-le-pattern-backend-for-frontend-bff-securise-%F0%9F%94%90-votre-application-web/">
              Explore
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style>
.title-card {
  white-space: pre-line;
}
</style>
